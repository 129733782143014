import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/hubs',
      name: 'hubs',
      component: () => import('@/views/hubs/MyHubs.vue'),
      meta: {
        pageTitle: 'My Hubs',
        breadcrumb: [
          {
            text: 'My Hubs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/hub-details/:id',
      name: 'hub-details',
      component: () => import('@/views/hubs/HubDetails.vue'),
      meta: {
        pageTitle: 'Hub Details',
        breadcrumb: [
          {
            text: 'Hub Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/producers',
      name: 'producers',
      component: () => import('@/views/producers/Producers.vue'),
      meta: {
        pageTitle: 'Producers',
        breadcrumb: [
          {
            text: 'Producers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/products/Products.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/Orders.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/invoices/Invoices.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoices',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices-corrections',
      name: 'invoices-corrections',
      component: () => import('@/views/invoices/InvoicesCorrections.vue'),
      meta: {
        pageTitle: 'Invoices Corrections',
        breadcrumb: [
          {
            text: 'Invoices Corrections',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoice-corrections-form',
      name: 'invoice-corrections-form',
      component: () => import('@/views/invoices/InvoiceCorrectionsForm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/export-data',
      name: 'export-data',
      component: () => import('@/views/ExportData.vue'),
      meta: {
        pageTitle: 'Export Data',
        breadcrumb: [
          {
            text: 'Export Data',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-management',
      name: 'user-management',
      component: () => import('@/views/users/UserManagement.vue'),
      meta: {
        pageTitle: 'User Management',
        breadcrumb: [
          {
            text: 'User Management',
            active: true,
          },
        ],
      },
    },
    {
      path: '/user-details/:id',
      name: 'user-details',
      component: () => import('@/views/users/UserDetails.vue'),
      meta: {
        pageTitle: 'User Details',
        breadcrumb: [
          {
            text: 'User Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/create-user',
      name: 'create-user',
      component: () => import('@/views/users/CreateUser.vue'),
      meta: {
        pageTitle: 'Create User',
        breadcrumb: [
          {
            text: 'Create User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
